import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["messageContent", "messagesContainer", "autoScrollCheck"]

  copyEmoji(ev) {
    var emoji = this.element.innerHTML

    var current_value = document.querySelector("#message_content").value
    document.querySelector("#message_content").value = current_value + emoji

    ev.stopPropagation()
  }

  resetForm() {
    // Clear input
    this.messageContentTarget.value = ''

    // Clear errors
    this.element.querySelector("#new_message .is-invalid")?.classList?.remove("is-invalid")
  }

  scrollToBottom(){
    if ( this.messagesContainerTarget && this.autoScrollCheckTarget.checked ) {
      this.messagesContainerTarget.scrollTo({top: this.messagesContainerTarget.scrollHeight})
    }
  }

}